import { apiRequest } from '../../utils/apiRequest';
import { toParams } from '../../utils/params';
import { getInviteMode } from '../../utils/apiInviteKey';
import { KbaAnswer } from '../../types/kba';

export const getKbaChallengeRequest = (lenderApplicationId: string) => apiRequest(
  `lender_application/${lenderApplicationId}/kba${toParams({ invite: getInviteMode() })}`, {
    method: 'GET',
  });

export const submitKbaChallengeRequest = (lenderApplicationId: string, answers: KbaAnswer[]) => apiRequest(
  `lender_application/${lenderApplicationId}/kba${toParams({ invite: getInviteMode() })}`, {
    method: 'POST',
    body: JSON.stringify({ answers }),
  },
);
